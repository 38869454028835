.table-bordered td {
  border: 0;
  border-top: 1px solid #c8ced3;
}
.table thead th {
  border: 0;
  border-bottom: 2px solid $black;
}

.add_agency .validation-error.form-control {
  margin-top: 0 !important;
}

.validation-error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: $error;
}

.invalid-input {
  .input-group-text {
    border-color: $error;
  }
  input {
    border-color: $error;
    &:focus {
      outline: none;
      border-color: $error;
      box-shadow: 0 0 0 0.2rem rgb(252, 203, 202);
    }
  }
}

.validation-error {
  border-color: $error;
}

.capitalize {
  text-transform: capitalize;
}

.loading-section {
  // border:1px solid #ddd;
  padding: 10px;
  text-align: center;
  width: 100%;
}

.ql-editor {
  height: 150px;
  background-color: #fff;
}

.editor{
  .ql-editor {
    * {
      word-break: break-word;
    }
  }
}

.DateRangePickerInput__withBorder {
  border-radius: 4px;
  padding: 0 4px;
}

.DateInput_input {
  padding: 3px 4px;
  font-size: 14px;
}

.tiktok-social-icon {
  height: 20px;
  margin-top: -3px;
}

.react-bs-table-sizePerPage-dropdown {
  button {
    background-color: #fff !important;
  }
}

.table-overlay {
  position: absolute;
  background: rgba(34, 34, 34, 0.3);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
  border-radius: 15px;
  
  div {
    color: #fff;
    font-size: 30px;
    position: absolute;
    left: 50%;
    top: 50%;
  }
}

.customTooltipItemBtn {
  color: $theme-color !important;
  text-decoration: none !important;
}

.tooltip-inner {
  color: white !important;
  font-weight: 700;
  background-color: $theme-color !important;
}

.tooltip .arrow::before {
  border-right-color: $theme-color !important;
}

// ======================
.toast {
  background-color: $theme-color !important;
  border-color: $theme-color !important;
  color: white !important;
}

.back-login {
  background-color: transparent !important;
  color: $theme-color !important;
  border: none !important;
  box-shadow: none !important;
}

.back-login i {
  color: $theme-color !important;
}

.creator-table .adminTable tbody tr :nth-child(12),
.creator-table .adminTable tbody tr :nth-child(7),
.invitation-table .adminTable tbody tr :nth-child(4) input,
.invitation-table .adminTable tbody tr :nth-child(4) {
  overflow: visible !important;
  text-overflow: clip !important;
}

// .invitation-table .adminTable tbody tr :nth-child(4) input,
// .invitation-table .adminTable tbody tr :nth-child(4) {
//   width: 250px !important;
// }

.creator-table .adminTable tbody tr :nth-child(7) {
  font-size: 12px !important;
}

.creator-table .adminTable tbody tr :nth-child(7) p span:not(:first-child) {
  margin-left: -5px;
}

// .react-bs-table table td,
// .react-bs-table table th {
//   overflow: visible !important;
// }

.creator-table .adminTable tbody tr .btn-ghost-primary,
.creator-table .adminTable tbody tr .btn-ghost-info {
  padding: 0.175rem 0.45rem !important;
}

.creator-table .adminTable tbody tr .btn-ghost-info:hover {
  color: white !important;
}

.creator-table .adminTable tbody tr p {
  margin-bottom: 0 !important;
}

// ========= Order Product Seller Details Modal ===========
.order-details-card {
  .avatar {
    vertical-align: middle;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  .order-product-details img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: top;
  }

  .list-group-item {
    border: none !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .order-product-details {
    .list-group-item {
      padding: 3px 0 !important;
    }
  }
}

.avatar {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 100%;
}

.table-responsive {
  /* width */
  &::-webkit-scrollbar {
    height: 7px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .table {
    td,
    th {
      white-space: nowrap;
    }
  }
}

// ======= Custom Img Input ==========
.customImgInput {
  width: auto !important;
  height: auto !important;
  min-width: auto !important;
  min-height: auto !important;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  input {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  i {
    position: absolute;
    bottom: 7%;
    left: 60%;
    padding: 4px;
    border-radius: 2px;
    background: white;
    color: $theme-color !important;
  }

  .imgBox {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }
}

// setting page
.custom-control-label {
  &::before {
    border-color: $theme-color;
  }
}
.custom-control-input {
  &:checked ~ .custom-control-label::before {
    color: $white;
    border-color: $theme-color;
    background-color: $theme-color;
  }
}
.custom-control-input:focus {
  ~ .custom-control-label::before {
    box-shadow: none;
  }
  &:not(:checked) ~ .custom-control-label::before {
    border-color: $theme-color;
  }
}

.border-top-1 {
  border-top: 1px solid #c8ced3;
}

.creator-view {
  .btn-link {
    color: #333333 !important;
    font-weight: 600;
  }
}

.sticker_list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 15px;
  .list-group-item {
    border: 1px solid #ddd !important;
    padding: 10px !important;
    .profileImgWrap {
      margin: auto;
      min-width: unset;
      min-height: unset;
      width: 90px;
      height: 90px;
      img {
        width: 100%;
        height: 100%;
        min-width: unset;
      }
    }
  }

  .customDropdown {
    position: absolute;
    top: -8px;
    right: -8px;
    button {
      background-color: transparent;
      border: none;
      i {
        color: $grey !important;
      }
      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }
      &:active {
        background-color: transparent !important;
      }
    }
  }
  .sticker_info {
    p {
      flex: 1 1 auto;
      color: $black;
      font-weight: 500;
    }
  }
}
input.validation-error.form-control {
  margin-top: 0 !important;
}

.uploadProfile.sticker_upload {
  margin-bottom: 0;
  border-radius: 50%;
  border-color: $theme-color;

  img {
    border-radius: 50%;
    object-fit: cover;
  }

  i {
    border-radius: 50%;
    background: $theme-color;
    color: $white !important;
  }
  & ~ .validation-error {
    text-align: center;
    margin-top: 20px;
  }
}

@media screen and (max-width: 576px) {
  .sticker_header {
    flex-wrap: wrap;
  }

  .sticker-title {
    width: 100%;
    text-align: center;
  }
}

// =========== Login Page ==========
.authFormWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 15px;
  width: 100%;
  position: relative;
}

.loginWrapper {
  width: 100%;
  max-width: 480px;
  margin-top: auto;
  margin-bottom: auto;
}

.projectLogo {
  width: 220px;
  cursor: pointer;
  display: block;
  margin: 20px auto 0;
}

.authPgFormWrap {
  border-radius: 10px;
  border: 1px solid #e6e6e6;
  padding: 25px;
  margin-top: 35px;
  margin-bottom: 40px;
}

.authPgFormWrap h4 {
  font-size: 20px;
  color: #4f4f4f;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
}

.loginWrapper h4 {
  margin-bottom: 25px;
  text-align: center;
  font-size: 24px;
  color: #333;
  font-weight: 600;
}

.loginWrapper .form-group {
  margin-top: 15px;
}

.backToLogin {
  text-decoration: none !important;
  display: block;
  text-align: center;
  max-width: 140px;
  margin: 15px auto 0;
  color: #828282 !important;
}

.btn.backToLogin {
  max-width: 100%;
}

// ====== Login Footer ========

.loginFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #828282;
  width: 100%;
}

.loginFooter .socialLinksFooter-Login,
.socialLinksFooter {
  display: flex;
}

.loginFooter * {
  font-size: 14px;
}

.loginFooter .socialLinksFooter a {
  color: #828282 !important;
}
.socialLinksFooter a {
  text-decoration: none !important;
  display: inline-block;
}

.socialLinksFooter a + a {
  margin-left: 10px;
}

.poweredBy a {
  color: #31c5c7;
  text-decoration: none !important;
  display: inline-block;
  margin-left: 5px;
}

// ======== Pay Per View Page ========
.ppv-details-page {
  .video-details-card {
    height: 100%;
    margin-bottom: 0;

    .card-header {
      position: relative;
      height: 343px;
      max-height: 343px;
      .ppvCoverImgOverlayWrap {
        height: 100%;
        img {
          height: 100%;
          object-fit: cover;
        }
      }

      video {
        height: 100%;
        object-fit: cover;
        background: #000000;
      }
    }

    .ppvVideoPreviewTag {
      bottom: 15px !important;
    }
  }

  .card-body {
    padding: 20px !important;
  }
}

.event-details-card {
  max-height: 470px;
  video {
    height: 100%;
    object-fit: cover;
    background: #000000;
  }

  img {
    height: 100%;
    object-fit: cover;
  }
}

// =========== Vault Detials Page ===========
.vault-details-page {
  .video-img-card .card-header {
    position: relative;
    height: 300px;
    background-color: white;
    overflow: hidden;

    video,
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 1px solid rgba(0, 0, 0, 0.08);
    }
  }
}

.dashboardCard-Main .card-header .bgIcon i.fa-exchange {
  transform: rotate(90deg) translate(0px, -10px);
}
