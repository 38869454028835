$white: #fff;

.card-Feed {
  // padding: 4px 8px;
  border: 0;
  box-shadow: rgba(2, 8, 20, 0.1) 0px 0.175em 0.5em,
    rgba(2, 8, 20, 0.08) 0px 0.085em 0.175em;
  margin: 15px 0;
  border-radius: 8px;
}

.card-Feed .card-header {
  background: $white;
  // padding: 6px 0 8px 0;
  padding: 10px 8px 8px 8px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: $grey;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-color: rgba(0, 0, 0, 0.1);
  letter-spacing: 0.3px;
  background-color: #f0f3f5;
  background: rgba(145, 117, 224, 0.15);
}

.card-Feed .card-header h4 {
  margin-bottom: 0;
  font-size: 17px;
}

.card-Feed .card-header .profileID {
  font-size: 15px;
  color: $black;
  text-decoration: none !important;
  cursor: pointer;
}

.postTime-Feed {
  color: #b1b1b1;
  font-size: 14px;
  letter-spacing: unset;
  margin-left: 10px;
}

.postTime-Feed {
  color: #888;
  text-align: center;
}

.card-Feed .card-body {
  // padding: 10px 0 6px;
  padding: 10px 8px 0;
}

.card-Feed .card-text {
  font-size: 15px;
  letter-spacing: 0.5px;
  color: $grey;
  margin-bottom: 10px;
}

.mediaPhotoWrap-Feed,
.mediaVideoWrap-Feed {
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  border-radius: 2px;
  margin-bottom: 10px;
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
}

.mediaPhotoWrap-Feed .carousel .carousel-item img,
.mediaPhotoWrap-Feed .carousel .carousel-item video {
  height: 375px;
  width: 100%;
}

.mediaPhotoWrap-Feed .carousel .carousel-item img {
  object-fit: cover;
  object-fit: contain;
}

.mediaPhoto-Feed {
  border-radius: 2px;
  max-height: 100%;
  max-width: 100%;
  // width: auto;
  // height: auto;
  width: 100%;
  height: 375px;
  object-fit: cover;
  object-fit: contain;
  background: #f5f5f5;
}

.options-Feed {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  // padding-top: 10px;
  padding: 10px 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  margin: 0 -8px;
  background-color: #f0f3f5;
}

.options-Feed span {
  color: $black;
}

.options-Feed .btn {
  padding: 6px 12px;
  line-height: 1;
  font-size: 16px;
  border: 1px solid transparent !important;
  background: transparent !important;
  box-shadow: none !important;
}

.options-Feed .btn i {
  margin-right: 8px;
  vertical-align: -1px;
  margin-top: 0 !important;
}

.carousel-control-prev,
.carousel-control-next {
  width: 9%;
  height: 20%;
  top: 40%;
}

audio:focus {
  outline: 0;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  cursor: pointer;
}

.customDropdown-Feed .nav-link {
  color: #c8ced3;
  padding: 6px 12px;
  line-height: 1;
  font-size: 16px;
  border: 1px solid transparent !important;
  background: transparent !important;
  box-shadow: none !important;
}

.customDropdown-Feed .nav-link:hover {
  color: #23282c;
}

.pollOptionWrap label {
  color: #777;
  font-size: 15px;
  letter-spacing: 0.3px;
}

.create-post-txt {
  margin-bottom: 0;
  color: $grey;
  letter-spacing: 0.3px;
}

.fansImgWrap {
  min-width: 36px;
  min-height: 36px;
  width: 36px;
  height: 36px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  padding: 2px;
  margin-right: 8px;
}

.fansImg {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

// msg preview
.msgPreviewWrap {
  width: calc(100% - 50px);
  left: 0;
  top: 0;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  background: $white;
  padding: 8px 2px;
  scrollbar-color: rgba(95, 66, 170, 0.6) #bbb;
  scrollbar-width: thin;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
}

.msgPreview {
  width: 56px;
  height: 56px;
  min-width: 56px;
  max-width: 56px;
  padding: 2px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: relative;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.msgPreview-media {
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.deletePreview {
  position: absolute;
  padding: 4px 3px 2px;
  font-size: 13px;
  border-radius: 50%;
  top: -7px;
  right: -5px;
  line-height: 1;
  background: #de2727 !important;
  box-shadow: none !important;
  outline: 0 !important;
  color: $white !important;
  border: 0;
  text-align: center;
}

.msgPreviewWrap::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background-color: #bbb;
}

.msgPreviewWrap::-webkit-scrollbar {
  background-color: #bbb;
  height: 6px;
}

.msgPreviewWrap::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: rgba(95, 66, 170, 0.6);
}

.messagePreview {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px 20px 8px 8px;
  border-radius: 4px;
  position: relative;
  font-size: 16px;
  line-height: 1;
}

// post locked
.postLocked,
.postUnlocked {
  position: relative;
}

.postLockedOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #7e57c2;
}

// comment section - feed
.commentSection {
  background: $white;
  margin-bottom: 15px;
  border-radius: 4px;
  padding: 18px 10px 12px;
  margin-top: -5px;
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2);
  max-height: 380px;
  overflow: auto;
  scrollbar-color: rgba(95, 66, 170, 0.8) #bbb;
  scrollbar-width: thin;
}

.commentSection::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background-color: #bbb;
}

.commentSection::-webkit-scrollbar {
  width: 8px;
  background-color: #bbb;
}

.commentSection::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  background-color: rgba(95, 66, 170, 0.8);
}

.personImg-Comment {
  min-width: 32px;
  min-height: 32px;
  width: 32px;
  height: 32px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  margin-right: 8px;
  font-size: 14px;
  color: $grey;
}

.commentSection .form-control {
  background: #f5f8fd;
}

.comments-Post {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 6px;
  font-size: 14px;
  color: $grey;
  margin-bottom: 2px;
  border: 1px solid #eee;
}

.comments-Post p {
  font-size: 14px;
  font-size: 14px;
  color: $grey;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.commentOptions .nav-link {
  color: #888;
  padding: 6px;
  margin-left: 4px;
  border-radius: 50%;
  line-height: 1;
  font-size: 13px;
  background: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  box-shadow: none !important;
}

.commentOptions .nav-link i {
  margin-top: 0 !important;
}

.replyBtn {
  font-size: 13px;
  color: #888;
  letter-spacing: 0.5px;
  padding: 4px 4px 2px;
  margin: 0;
  width: min-content;
  background: transparent !important;
  border: 0;
  box-shadow: none !important;
}

.commentTime {
  padding: 4px 0 2px;
  font-size: 13px;
  color: #888;
  letter-spacing: 0.5px;
  margin-left: 15px;
  position: relative;
}

.commentTime:before {
  position: absolute;
  top: 4px;
  left: -12px;
}

.videoPlayBtn {
  height: 50px;
  width: 50px;
  line-height: 1;
  border: 1px solid $black !important;
  border-radius: 50%;
  color: $black !important;
  font-size: 22px;
  background: $white !important;
  box-shadow: 0 1px 1px 0 rgba(60, 75, 100, 0.14),
    0 2px 1px -1px rgba(60, 75, 100, 0.12), 0 1px 3px 0 rgba(60, 75, 100, 0.2) !important;
}

.videoPlayBtn i {
  margin-top: 0 !important;
  margin-left: 3px;
}

// admin panel specific

.ml-0 {
  margin-left: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.capitalize {
  text-transform: capitalize;
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
