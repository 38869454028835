@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

body {
  background-color: $white;
  font-family: "Poppins", sans-serif;
  color: $grey;
  counter-reset: quiz-opts;
}

.text-theme {
  color: $theme-color !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.card {
  border-radius: 12px !important;
}

.readMoreText {
  color: $theme-color !important;
  padding-left: 10px;
}

select[data-test="creator-filter"],
td select {
  width: unset !important;
}

select,
option {
  text-transform: capitalize;
}

.clearfix:after {
  display: block;
  clear: both;
  content: "";
}

.box-container {
  background: white;
  padding: 20px;
  text-align: left;
  margin: 10px 0 25px;
  border: 1px solid #c8ced3 !important;
  border-radius: 15px;
  .BtnPurple,
  .btn {
    text-align: center !important;
  }
}

.input-group-text {
  border: none !important;
}

.table-checkbox {
  .table tr {
    td:first-child,
    th:first-child {
      padding: 0 !important;
      input {
        margin-top: 5px;
        transform: scale(1.2);
      }
    }
  }
}

.addressWrap {
  div {
    label {
      font-size: 16px;
      min-width: 100px;
    }

    p {
      font-size: 15px;
    }
  }
}

.modal-title {
  font-size: 16px !important;
  color: $grey !important;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 1.4;
}

.modal-header {
  padding: 18px 15px 14px !important;
  border-color: rgba(0, 0, 0, 0.05) !important;
  letter-spacing: 0.5px !important;
  .close {
    padding: 0.3rem 0.6rem !important;
    margin: -0.15rem -0.6rem -1rem auto !important;
    font-size: 26px !important;
    outline: 0 !important;
    line-height: 18px;
    color: #e50914;
    opacity: 0.7;
  }
}

.modal-body label {
  font-size: 14px;
  color: $mediumGray;
  text-transform: uppercase;
}

label {
  color: $mediumGray;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.3px;
}

.form-control {
  color: $lightGray !important;
  padding: 6px 10px;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  border: 1px solid #eff0f6;
  background-color: #eff0f6 !important;
  border-radius: 8px;
  letter-spacing: 0.3px;
  height: 46px;
}

.form-control:focus {
  box-shadow: none !important;
  border: none !important;
}

.form-error {
  display: block !important;
  color: #f16667;
  font-size: 14px;
  padding-left: 5px;
  margin: 3px 0 0;
}

.forgotPassword {
  float: right;
  padding: 0;
  font-size: 16px;
  background: transparent !important;
  border: 0 !important;
  outline: 0 !important;
  color: $theme-color !important;
  box-shadow: none !important;
  font-weight: 500;
}

.eyeIcon,
.spinnerLogin {
  position: absolute;
  top: 11px;
  right: 12px;
  color: $grey;
  font-size: 16px;
  .fa {
    color: $grey !important;
    cursor: pointer;
  }
}

.themeBtn {
  max-width: 260px;
  font-weight: 600;
  width: 100%;
  background: $theme-color !important;
  font-size: 16px;
  color: $white !important;
  border: 0 !important;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
  border-radius: 30px;
  margin: 10px 0;
  height: 42px;
  line-height: 1;
  padding: 0 6px;
  position: relative;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.loginBtn {
  border: 1px solid $theme-color !important;
  height: 50px;
  width: 100%;
  max-width: 300px;
  padding: 0 5px;
  color: $white !important;
  background: $theme-color !important;
  font-size: 14px;
  border-radius: 25px;
  letter-spacing: 0.5px;
  margin: 30px auto 10px;
  line-height: 48px;
  position: relative;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  display: block;
}

.registerBtn {
  color: #999 !important;
  font-size: 14px;
  padding: 6px 0;
  background: transparent !important;
  border: 0 !important;
  outline: 0 !important;
  box-shadow: none !important;
  display: block;
  margin: 10px auto 0;
  position: relative;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  letter-spacing: 0.3px;

  span {
    color: $theme-color;
    text-decoration: underline;
  }
}

// ====== Table =======
.details-page .table {
  width: 100% !important;
}

.table {
  border: 1px solid #c8ced3 !important;
  overflow: hidden;
  padding: 20px 12px;
  border-radius: 15px;
  margin: 10px 0 25px;
  td,
  th {
    white-space: nowrap;
    font-size: 12px !important;
    border: none !important;
  }
  thead {
    th {
      color: $grey !important;
      font-weight: 600 !important;
    }
  }

  tbody {
    td {
      color: $mediumGray !important;
      font-weight: 400 !important;
      a,
      .btn-link,
      > span {
        cursor: pointer;
        text-transform: capitalize;
        color: $theme-color !important;
        text-decoration: none !important;
      }

      select {
        min-width: 120px !important;
      }

      a {
        .btn-ghost-primary {
          padding: 0 !important;
        }
      }
    }
  }

  &.table-hover tbody tr:hover {
    background-color: transparent !important;
  }
}

.react-bs-table-container {
  border: 1px solid #c8ced3 !important;
  overflow: hidden;
  padding: 12px 10px 25px;
  border-radius: 15px;
  background-color: white;
  margin: 10px 0 25px;

  .table {
    border: none !important;
    border-radius: 0;
    padding: 0;
    margin-bottom: 0px;
  }
}

.react-bs-table-pagination .row {
  margin: 0 !important;
}

.order.fa-sort {
  display: none;
}

// date range
.DateRangePickerInput__withBorder {
  border-radius: 8px !important;
  border: 0 !important;
  background-color: #eff0f6 !important;
  width: 100%;
}

.DateRangePickerInput__withBorder .DateInput {
  width: calc(50% - 12px) !important;
  border-radius: 8px;
}

.DateRangePicker_picker {
  z-index: 99 !important;
}

.DateInput_input {
  display: block;
  width: 100%;
  height: 46px;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  background-clip: padding-box;
  color: $lightGray !important;
  font-family: "Poppins", sans-serif;
  font-size: 14px !important;
  border: 1px solid #eff0f6 !important;
  padding: 6px 8px !important;
  border-radius: 8px !important;
  background-color: #eff0f6 !important;
  outline: 0 !important;
}

.DateRangePickerInput_arrow_svg {
  width: 20px !important;
  height: 20px !important;
  margin-top: -4px;
  margin-right: 4px;
}

.CalendarMonth_caption {
  font-size: 16px !important;
  color: #000 !important;
}

.DayPicker_weekHeader_li {
  color: #666;
}

.CalendarDay__default {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: $black !important;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: $theme-color !important;
  color: $white !important;
  border-color: $theme-color !important;
}

.CalendarDay__selected_span,
.CalendarDay__hovered_span,
.CalendarDay__selected_span:hover {
  background: rgba(95, 66, 170, 0.5) !important;
  border-color: rgba(95, 66, 170, 0.5) !important;
}

.CalendarMonth_table {
  margin-top: 3px;
  box-shadow: none;
}

.DayPicker__withBorder {
  border-radius: 4px !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight {
  display: none;
}

// ========== Search Box ========
.search-box {
  input {
    box-shadow: none !important;
    outline: none !important;
    border: none !important;
    max-width: 200px;
  }
  .input-group-text {
    background-color: #eff0f6 !important;
    border: none !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    cursor: pointer;
  }
  .icon-magnifier {
    color: $grey !important;
  }
}

// ========== Invitations ==========
.sendReminder {
  i {
    color: white !important;
    margin-left: 5px;
  }
}

.status {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .referralStatus {
    margin-right: 10px;
  }
  .referralStatus,
  .sendReminder {
    margin-bottom: 10px;
    font-size: 12px !important;
    color: white !important;
    border: none;
    line-height: 1;
    border-radius: 20px;
    padding: 8px 10px !important;

    &::before {
      display: none !important;
    }
  }
}

.sendReminderModal {
  .title {
    font-size: 22px !important;
    line-height: 1.4;
    font-weight: 400 !important;
    &::first-letter {
      text-transform: capitalize;
    }
  }

  .resend-invitation,
  .close-btn-icon {
    border-radius: 25px;
  }

  .resend-invitation {
    background: $theme-color !important;
    color: white !important;
    border: 0 !important;
  }

  .close-btn-icon {
    border: 1px solid #aaaaaa;
    color: #aaaaaa;
    padding: 7px 25px;
    &:hover {
      background-color: #aaaaaa;
      color: white;
    }
  }
}

// ======== Modal Btn Save & Close ===========
.modalBtnCancel,
.modalBtnSave {
  background-color: $theme-color !important;
  color: $white !important;
  font-size: 14px;
  border: 1px solid $theme-color !important;
  padding: 0 15px;
  position: relative;
  overflow: hidden;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  border-radius: 25px;
  height: 36px;
  line-height: 1;
  font-weight: 600;
  margin: 0 !important;
}

.modalBtnCancel {
  background-color: $white !important;
  color: #ccc !important;
  border: 1px solid #ccc !important;
  margin-right: 10px !important;
  box-shadow: none !important;
}

// ======== Settings ===========
.setting {
  .input-group-text {
    background-color: #dcdcdc !important;
    border: none !important;
  }
}

// ====== Transactions Page ==========
.transaction-page {
  .box-container {
    width: 50%;
    margin-left: auto;
    margin-right: auto;

    align-items: center;
    justify-content: center;
    > div {
      width: 100%;
      select {
        width: 80%;
      }
    }
  }
}

// Custom Card
.custom-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #c8ced3;
  border-radius: 0.25rem;

  .custom-card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: #f0f3f5;
    border-bottom: 1px solid #c8ced3;

    &:first-child {
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    }
  }

  .custom-card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }
}

// Accordion
.accordion {
  ul {
    list-style: none;
    overflow: hidden;

    li {
      .card,
      .custom-card {
        overflow: hidden;
        border-radius: 10px !important;
        border: none !important;
        margin-bottom: 0 !important;

        .card-header,
        .card-body,
        .custom-card-header,
        .custom-card-body {
          padding: 7px 0 !important;
          font-size: 15px;
        }

        .card-header,
        .custom-card-header {
          cursor: pointer;
          min-height: unset !important;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: #fff !important;

          .card-title,
          .custom-card-title {
            margin-bottom: 0 !important;
            text-transform: capitalize;
            max-width: 30ch;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            b {
              font-weight: 500 !important;
              padding-left: 7px;
            }
          }
        }

        .custom-card-header {
          border: none !important;
        }

        .custom-card-body {
          border-top: 1px solid #c8ced3 !important;
        }
      }

      &:last-child {
        .card-header {
          border: none !important;
        }

        .card-body {
          border-top: 1px solid #c8ced3 !important;
        }
      }

      .rotateIcon {
        transform: rotate(180deg);
        transition: all 0.3s;
        vertical-align: -1px;
      }
    }
  }
}

// Course Quiz Ques List
.quiz-ques-opt {
  max-height: 450px;
  overflow-y: auto;

  /* width */
  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  > ul {
    padding: 12px;
    > li {
      margin-bottom: 12px;
      padding-bottom: 8px;
      &:not(:last-child) {
        border-bottom: 1px dashed #c8ced3;
      }

      b {
        display: inline-block;
        font-weight: 600;
        margin-bottom: 8px;
      }

      ul.quiz-opts {
        li {
          margin-bottom: 4px;
          &.checked {
            color: rgb(9, 107, 9);
            font-weight: 600;
          }
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media all and (max-width: 1199.98px) {
  .table td,
  .table th {
    font-size: 12px !important;
  }

  .status {
    .referralStatus,
    .sendReminder {
      font-size: 12px !important;
    }
  }
}
