:root {
  --primary-color: #5f42aa;
  --primary-color-rgb: 95, 66, 170; // rgba color of #5f42aa (primary-color)
}

$theme-color: var(--primary-color);
$grey: #333333;
$mediumGray: #4f4f4f;
$lightGray: #828282;
$border: #e4e4e4;
$white: #fff;
$black: #1f1f1f;
$error: #dc3545;

:export {
  themeColor: $theme-color;
}

// Import styles
@import "~@coreui/coreui/scss/coreui.scss";

// Temp fix for reactstrap
@import "~@coreui/coreui/scss/_dropdown-menu-right.scss";

@import "common";

// If you want to add something do it here
@import "custom";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

#root ~ iframe {
  display: none;
}

body {
  font-family: "Poppins", sans-serif;
  color: $mediumGray;
}

.fw-400 {
  font-weight: 400 !important;
}

#root ~ iframe {
  display: none !important;
}

.fs14 {
  font-size: 14px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.text-primary {
  color: $theme-color !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.bg-theme {
  background-color: $theme-color !important;
}

.list-none {
  list-style: none;
}

@mixin ripple {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $white 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10, 10);
    opacity: 0;
    transition: transform 0.5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0, 0);
    opacity: 0.3;
    transition: 0s;
  }
}

@mixin glossy-button {
  position: relative;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 55%;
    border-radius: 25px;
    background: linear-gradient(
      rgba(255, 255, 255, 0.3),
      rgba(255, 255, 255, 0.2)
    );
  }
}

.BtnPurple,
.btn-primary,
.btn-outline-primary {
  font-size: 14px;
  color: $white !important;
  letter-spacing: 0.3px;
  border: 1px solid transparent !important;
  background: $theme-color !important;
  box-shadow: none !important;
  outline: 0 !important;
  border-radius: 25px;
  padding: 0 20px;
  height: 38px;
  font-weight: 500;

  @include ripple;

  i {
    color: white !important;
  }
}

.btn-outline-primary {
  color: $theme-color !important;
  border-color: $theme-color !important;
  background-color: transparent !important;

  i {
    color: $theme-color !important;
  }
}

.btn-danger {
  color: white !important;
  background-color: $error !important;
  border-color: $error !important;

  i {
    color: $white !important;
  }
}

.btn-link {
  color: $theme-color !important;
  text-transform: capitalize;
}

.TruFansPgBg {
  background: #f5f6f8;
  min-height: unset;
  height: calc(100vh - 105px);
  overflow: auto;
  padding: 0 15px;
}

.PgTitle {
  margin: 25px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: $grey;
    letter-spacing: 0.3px;
    margin-bottom: 0;
    font-weight: 600;
    font-size: 24px;
    text-transform: uppercase;
  }

  .spinner-border {
    color: $theme-color;
    width: 25px;
    height: 25px;
    font-size: 14px;
    margin-top: -4px;
    vertical-align: middle;
  }

  select {
    background-color: white !important;
  }
}

.theme-color {
  color: $theme-color !important;
}

.customBackBtn {
  padding: 0 6px 0 2px;
  font-size: 20px;
  color: $grey !important;
  margin-right: 10px;
  line-height: 1;
  background: transparent !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
  // margin-top: -3px;
}

label {
  color: $mediumGray;
  margin-bottom: 4px;
  letter-spacing: 0.3px;
}

.form-control {
  color: $grey;
  letter-spacing: 0.3px;
  font-size: 14px;
  padding: 6px 10px;
}

// .adminTable {
//   border-radius: 6px;
//   box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12),
//     0 10px 10px rgba(90, 97, 105, 0.06), 0 7px 70px rgba(90, 97, 105, 0.1);
// }

.table {
  thead {
    background: $white;
  }

  thead th:first-child {
    border-top-left-radius: 6px;
  }

  thead th:last-child {
    border-top-right-radius: 6px;
  }

  thead th {
    border-bottom-width: 2px;
    border-bottom-color: $black;
    border-top: 0;
    font-size: 14px;
    color: #5a6169;
    font-weight: 600;
    background: $white;
  }

  tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
  }

  tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
  }

  tbody td {
    vertical-align: middle;
    font-size: 14px;
    color: #5a6169;
    font-weight: 400;
  }

  tbody tr {
    background: $white;

    .inStock {
      color: #388e3c !important;
    }

    .outStock {
      color: $error !important;
    }
  }

  .form-control {
    padding: 4px 5px;
    font-size: 14px;
    letter-spacing: 0;
  }

  .btn {
    font-size: 14px;
  }

  tbody td > span {
    text-decoration: underline;
    cursor: pointer;
    text-decoration-color: $black;
  }

  .BtnPurple {
    height: auto;
    padding-block: 4px;
  }
}

.badge-warning {
  background-color: #f9bb00;
}

.breadcrumb {
  margin-bottom: 0;
}

.main {
  background-color: #f5f6f8;

  .container-fluid {
    padding: 0;
  }
}

.powered-by a {
  color: $theme-color;
  text-decoration: none !important;
  display: inline-block;
  margin-left: 3px;
}

.app-footer {
  color: $lightGray;
  letter-spacing: 0.3px;
  background-color: $white;
  border-top-color: rgba(0, 0, 0, 0.06);
  font-size: 14px;
}

.SocialLinksFooter {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  a {
    text-decoration: none !important;
    color: $lightGray !important;
    display: inline-block;
    margin-left: 10px;
  }
}

.LoginFooter a {
  color: $white !important;
}

.modal-header,
.modal-footer {
  padding: 0.6rem 1rem 0.5rem;
  border-color: rgba(0, 0, 0, 0.1);
  letter-spacing: 0.5px;
}

.modal-footer {
  padding: 0.65rem 1rem 0.6rem;

  .btn {
    letter-spacing: 1px;
  }
}

.modal-title {
  font-size: 20px;
  color: $grey;
}

.modal-body {
  color: $grey;
}

.modal-header .close {
  padding: 0.5rem 1rem;
  margin: -0.2rem -1rem -1rem auto;
  font-size: 20px;
  outline: 0 !important;
}

// spinner
.spinnerWrap {
  display: block;
  text-align: center;
  margin-top: 30px;
}

// header & side menu css
.navbar-nav .nav-link.active {
  font-weight: 500;
}

.app-header {
  border-bottom-color: rgba(0, 0, 0, 0.08);

  .nav-item {
    padding: 0 7px;
    .nav-link {
      font-size: 14px;
    }
  }

  .navbar-toggler {
    outline: 0 !important;
  }

  .nav-item .nav-link .badge {
    margin-top: -17px;
    border-radius: 4px;
    padding: 2px 6px 2px;
    letter-spacing: 0.5px;
    font-size: 82%;
  }
}

.customDropdown--Header {
  box-shadow: 0 4px 5px 0 rgba(60, 75, 100, 0.14),
    0 1px 10px 0 rgba(60, 75, 100, 0.12), 0 2px 4px -1px rgba(60, 75, 100, 0.2);
  border: 0;
  top: 13px !important;

  .dropdown-item {
    padding: 10px 12px;
    outline: 0 !important;
    font-size: 15px;
    background: transparent;
    color: $grey !important;
    letter-spacing: 0.5px;
    border-bottom-color: rgba(0, 0, 0, 0.1);
  }

  .dropdown-item:focus {
    background: transparent;
  }

  .dropdown-item i {
    margin-left: 0;
    color: #aaa;
    width: 16px;
  }
}

.sidebar {
  background: $grey;

  .nav {
    padding-top: 5px;
  }

  .nav-link {
    padding: 0.75rem;
  }

  .nav-link .nav-icon {
    color: #bbb;
    margin-right: 0.6rem;
  }

  .nav-link.active {
    background: $black;
  }

  .nav-link:hover {
    background: transparent;
  }

  .nav-link.active .nav-icon {
    color: #f5f5f5;
  }
}

.filterWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  background: $white;
  border-radius: 15px;
  padding: 20px 10px 5px;

  > div:first-child {
    .ml-2 {
      > * {
        width: 100%;
      }
    }
  }

  > div:last-child {
    align-items: flex-start !important;
  }

  select {
    width: 160px;

    &:not(:first-child) {
      margin-left: 10px;
    }
  }

  .fa {
    font-size: 20px;
    color: $grey;
  }

  > div {
    margin: 0 5px 15px 5px;
  }
}

.filterWrap-TransactionsPg {
  flex-wrap: wrap;

  select {
    width: unset;
  }
}

// login page
.loginPgBg {
  background-image: linear-gradient(90deg, #ff409f 0%, $black 100%) !important;
  align-items: center;
  flex-direction: row;

  .card {
    border: 0;
    border-radius: 15px !important;
    background: $white;

    .card-body {
      padding: 1rem 2rem 1.25rem;
    }
  }

  .card:first-child {
    border-right: 1px solid $theme-color;
  }

  h2 {
    color: $black;
    font-weight: 700 !important;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 6px;
    font-size: 26px;
  }

  .btn-link {
    color: $black;
    text-decoration: none !important;
    font-size: 14px;
    letter-spacing: 0.3px;
  }

  .BtnPurple {
    padding: 8px 24px;
    letter-spacing: 1px;
    margin-top: 17px;
    font-size: 15px;
  }
}

.loginPgContent {
  max-width: 500px;
  margin: 0 auto;
  padding-top: 25px;
  background: $white;
  border-radius: 8px;
  box-shadow: 1px 1px 10px 5px rgba(85, 61, 61, 0.35);

  .card-group {
    background: $white;
    border-radius: 8px;
    color: $black;
    padding: 25px 5px 30px;
  }

  .card:first-child {
    border-right: 0;
  }
}

.company-logo {
  width: 260px;
  margin: 15px auto 40px;
  display: block;
  cursor: pointer;
}

.card-group > .card {
  margin-bottom: 0;
}

.login-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $white;
  max-width: 500px;
  margin: 15px auto 10px;
}

.login-footer * {
  letter-spacing: 0.3px;
  font-size: 14px;
}

.loginPgContent {
  max-width: 500px;
  padding-top: 0;

  .card-group {
    padding: 5px 5px 2px !important;
  }

  .BtnPurple {
    margin-top: 8px;
  }
}

.personImgWrap {
  min-width: 36px;
  min-height: 36px;
  width: 36px;
  height: 36px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  padding: 1px;
  margin-right: 8px;

  img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.personImg {
  width: 32px;
  min-width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.referralStatus {
  padding: 5px 10px;
  color: $white !important;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 400;
  letter-spacing: 1px;
  border-radius: 25px;
  text-decoration: none !important;

  @include glossy-button;
}

.messageHeight {
  display: block;
  display: -webkit-box;
  max-width: 300px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 20px;
  max-height: 40px;
}

.ProfileCard {
  width: 100%;
  border: 1px solid #c8ced3 !important;
  border-radius: 15px;
  overflow: hidden;

  .card-header {
    min-height: 50px;
    background: $theme-color;
    border-top-left-radius: 0.625rem;
    border-top-right-radius: 0.625rem;
  }

  .card-body {
    padding: 0.75rem 1.2rem;
  }

  h4 {
    color: $grey;
    font-weight: 600;
    font-size: 19px;
  }

  p {
    color: #818ea3;
  }

  .react-bs-table-container {
    border: none !important;
    padding: 0;
    border-radius: 0;
    background-color: transparent !important;
    margin: 0 !important;

    .table {
      th {
        padding: 0;
        line-height: 1 !important;
      }

      tbody tr {
        border-top: 1px solid #818ea3;

        td {
          padding-left: 0;
          padding-right: 0;
          line-height: 1.4 !important;
        }
      }
    }
  }
}

.profileImgWrap {
  min-width: 106px;
  min-height: 106px;
  width: 106px;
  height: 106px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  padding: 2px;
  margin-top: -50px;
}

.profileImg {
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

// custom switch
.custom-switch {
  &.custom-control {
    display: flex;
    min-height: 0;

    .custom-control-label {
      &::before {
        border-color: $border;
        top: 50%;
        transform: translateY(-50%);
      }

      &::after {
        top: 50%;
        transform: translateY(-50%) !important;
      }
    }
  }

  // checked
  .custom-control-input:checked + .custom-control-label::before {
    background-color: $theme-color;
    border-color: $theme-color;
  }

  .custom-control-input:checked ~ .custom-control-label::after {
    transform: translateY(-50%) translateX(0.75rem) !important;
  }

  // disabled
  .custom-control-input:disabled + .custom-control-label::before {
    background-color: #ccc !important;
    border-color: #ccc !important;
  }
}

.profileInfo,
.ordersPgList {
  .list-group-item {
    border: 0;
    padding: 13px 4px;
    border-bottom: 1px dashed rgba(0, 0, 0, 0.08);
    margin-bottom: 0;
    color: $black;
  }

  .list-group-item:last-child {
    border-bottom: 0;
  }

  p {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #818ea3;
    margin-bottom: 0;
    letter-spacing: 0.3px;
  }

  p span,
  p a {
    color: $grey;
    margin-left: 10px;
    font-weight: 600;
  }

  p a {
    text-decoration: underline;
  }

  p i {
    font-size: 16px;
    vertical-align: -1px;
    margin-right: 12px;
  }
}

.profileInfo_social {
  display: flex;
  justify-content: space-between;

  a {
    display: inline-block;
    border-radius: 50%;
    text-align: center;
    width: 30px;
    height: 30px;
    background: #e9ecef;
    color: #818ea3;
    margin: 0 4px;
    font-size: 15px;
  }

  i {
    line-height: 30px;
  }
}

.idProofImgWrap {
  min-width: 131px;
  min-height: 131px;
  width: 131px;
  height: 131px;
  overflow: hidden;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 2px;
  display: block;
  margin: 15px auto 15px;
}

.idProofImg {
  width: 125px;
  min-width: 125px;
  height: 125px;
  border-radius: 4px;
  object-fit: cover;
}

.addressWrap,
.bankInfoWrap {
  margin-top: 12px;

  label {
    color: #818ea3;
    font-size: 13px;
    margin-bottom: 2px;
  }

  p {
    color: #3d5170;
    // font-weight: 400;
    // margin-bottom: 9px;
    font-weight: 600;
    padding-bottom: 6px;
    margin-bottom: 6px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }
}

.addressWrap p:last-child,
.addressWrap p:first-child,
.bankInfoWrap p:last-child {
  border-bottom: 0;
  padding-bottom: 2px;
}

.customTabs--adminapp {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);

  .nav-link,
  .nav-link:hover {
    border: 0;
    border-bottom: 4px solid transparent;
    text-align: center;
    padding: 0.5rem 0.75rem;
    color: $grey;
    font-size: 14px;
  }

  .nav-link div {
    font-size: 20px;
    color: $black;
  }

  .nav-link.active {
    border: 0;
    border-bottom: 4px solid $theme-color;
    background: transparent;
  }
}

.customTabContent--adminapp {
  border: 0;
  margin-top: 0;
  background: transparent;

  .tab-pane {
    padding: 1.2rem 0.25rem 1rem;
  }
}

.dashboardCard,
.dashboardCard-Graph {
  border: 1px solid #c8ced3 !important;
  overflow: hidden;
  border-radius: 15px;
}

.dashboardCard,
.dashboardCard-Main {
  .card-header {
    background: transparent;
    padding: 0.8rem 1rem 0.7rem;
    border: 0;
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #818ea3;

    .bgIcon {
      color: rgba(95, 66, 170, 0.75);

      img {
        height: 26px;
        margin-top: -1px;
      }

      i {
        font-size: 25px;
        color: $grey !important;
      }
    }
  }

  .card-body {
    padding: 1rem 0.75rem 0.8rem;
    text-align: center;
    color: #3d5170;
    font-weight: 600;
    font-size: 21px;
  }
}

.dashboardCard-Graph {
  .card-header {
    display: flex;
    align-items: center;
    font-weight: 600;
    color: $grey;
    font-size: 16px;
    min-height: 80px;
    line-height: 1;
    background-color: $white;
  }
}

.fs-16 {
  font-size: 16px;
}

.inviteAddSub {
  font-size: 17px;
  padding: 0.15rem 0.75rem;
  font-weight: bold;
  background-color: $white;
  cursor: pointer;
}

.dashboardCard-Main {
  .card-body {
    color: $theme-color;
    font-size: 25px;
    padding: 2rem 0.75rem 0.8rem;
  }

  .card-header {
    position: relative;
    padding-bottom: 1rem;

    .bgIcon {
      position: absolute;
      right: -12px;
      top: -12px;
      color: rgba(95, 66, 170, 0.75);

      img {
        height: 67px;
        margin-top: 0;
      }

      i {
        font-size: 65px;
        margin-top: 0;
        color: $theme-color !important;
      }
    }
  }
}

.uploadProfile {
  background: $white !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
  width: 108px;
  height: 108px;
  border-radius: 6px;
  display: block;
  margin: 0 auto 25px !important;
  position: relative;

  img {
    height: 108px;
    width: 108px;
    border-radius: 4px;
    object-fit: cover;
  }

  i {
    font-size: 14px;
    color: #999;
    width: 30px;
    height: 30px;
    line-height: 28px;
    position: absolute;
    bottom: -13px;
    left: 38px;
    background-color: $white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 6px;
  }
}

.customCheckbox {
  .custom-control-label::before,
  .custom-control-label::after {
    top: 1px;
  }
}

.subscriberView,
.vaultView,
.agencyView {
  .adminTable {
    box-shadow: none;
  }
}

.app-header .navbar-brand {
  width: 120px;
  margin-left: 10px;
  margin-right: 10px;
}

.react-bs-table-bordered {
  border: 0 !important;
}

.table thead th {
  color: #5a6169 !important;
  font-weight: 800;
}

.new-table {
  td,
  th {
    td,
    th {
      padding: 0 20px 0 0;
      border: 0;
      font-weight: normal;
    }

    td {
      padding: 0 40px 0 0;
    }
  }

  &.table {
    border: none !important;

    tbody tr {
      border-top: 1px solid #818ea3;

      td {
        padding-left: 0;
        padding-right: 0;
        line-height: 1.4 !important;
      }
    }
  }
}

.events-view {
  margin-bottom: 20px;
}

.new-card-footer p {
  font-size: 20px;
  text-align: center;
  color: $theme-color;
  margin: 0;
  font-weight: bold;
}

.event-details-card {
  padding: 0;

  .card-header {
    padding: 0;
    height: auto;
    overflow: hidden;

    img {
      max-width: 100%;
    }
  }
}

.ProfileCard {
  table {
    margin-bottom: 0;

    th {
      border-top: 0;
      text-align: center;
    }

    td {
      text-align: center;
    }
  }
}

img.price-icon {
  max-width: 25px;
}

img {
  font-size: 12px;
}

/*------------------------------*/
.btn-ghost-primary,
.page-link {
  color: $theme-color;
}

.page-item.active .page-link {
  background-color: $theme-color;
  border-color: $theme-color;
}

.table tbody td {
  color: $black;
}

.table-bordered td {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.fa,
i {
  color: #999 !important;
}

i.text-white {
  color: white !important;
}

i.text-danger {
  color: #ef8157 !important;
}

i.text-success {
  color: #6bd098 !important;
}

.bgIcon i {
  color: $theme-color !important;
}

.card-Feed .card-header {
  background: rgba(95, 66, 170, 0.1) !important;
}

.table thead th {
  color: $black !important;
}

.btn-ghost-primary:hover,
.btn-ghost-primary:focus,
.btn-ghost-primary:active,
.btn-ghost-primary:not(:disabled):not(.disabled):active {
  color: $theme-color;
  background-color: transparent;
  border-color: transparent;
  outline: none !important;
  box-shadow: none !important;
}

.BtnPurple .fa,
i {
  color: $white !important;
}

.react-bs-table-pagination {
  margin-top: 15px !important;

  .col-md-6 p {
    color: $black !important;
    margin: 10px 0 0;
  }

  .react-bootstrap-table-page-btns-ul.pagination {
    margin-bottom: 0 !important;
  }
}

/*-------------------------------------------------------*/
header.app-header.navbar {
  .ml-auto i {
    color: $black !important;
    font-size: 14px;
    cursor: pointer;
  }
}

.powered-by a {
  color: #31c5c7 !important;
}

.navbar-nav .nav-link.active,
.navbar-nav .open > .nav-link,
.navbar-nav .open > .nav-link:hover,
.navbar-nav .open > .nav-link:focus,
.navbar-nav .active > .nav-link,
.navbar-nav .active > .nav-link:hover,
.navbar-nav .active > .nav-link:focus,
.navbar-nav .nav-link.open,
.navbar-nav .nav-link.open:hover,
.navbar-nav .nav-link.open:focus,
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.active:hover,
.navbar-nav .nav-link.active:focus {
  color: $theme-color;
}

.loginPgBg {
  background-image: linear-gradient(180deg, #f29847 0%, $white 100%) !important;

  .login-footer {
    color: #585858;
  }

  .forgot-icon,
  .icon-lock-open {
    color: #999 !important;
  }
}

.table.table-bordered input {
  // width: 160px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.table.table-bordered input[type="checkbox"] {
  width: auto;
}

.videoPreviewTag {
  background-color: $theme-color !important;
  color: $white !important;
  border: 1px solid $theme-color !important;
  outline: 0 !important;
  box-shadow: none !important;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  border-radius: 5px;
  padding: 7px 6px 5px;
  position: absolute !important;
  bottom: 118px;
  left: 8px;
}

.vaultVideoPreviewTag {
  background-color: $theme-color !important;
  color: $white !important;
  border: 1px solid $theme-color !important;
  outline: 0 !important;
  box-shadow: none !important;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  border-radius: 5px;
  padding: 7px 6px 5px;
  position: absolute !important;
  bottom: 20px;
  left: 28px;
}

.ppvVideoPreviewTag {
  background-color: $theme-color !important;
  color: $white !important;
  border: 1px solid $theme-color !important;
  outline: 0 !important;
  box-shadow: none !important;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  border-radius: 5px;
  padding: 7px 6px 5px;
  position: absolute !important;
  bottom: 98px;
  left: 18px;
}

.videoPlayBtnWrap {
  position: absolute;
  height: 18%;
  top: 41%;
  width: 18%;
  left: 41%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.videoPreviewPlayBtn {
  height: 50px;
  width: 50px;
  line-height: 1;
  border: 1px solid $theme-color !important;
  border-radius: 50%;
  font-size: 22px;
  background: $theme-color !important;
  box-shadow: none !important;
  @include ripple;
}

.videoPreviewPlayBtn i {
  color: $white !important;
  margin-top: 1px !important;
  margin-left: 3px;
}

.ppvCoverImgOverlayWrap {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.Customize_products {
  h5.card-title {
    margin-bottom: 8px;
  }

  span.price {
    font-weight: 500;
  }

  img.filterIcon {
    margin: 0;
  }
}

.cursorPointer {
  cursor: pointer !important;
}

.app-header .nav-item {
  min-width: unset;
}

.app-header .nav-item .nav-link {
  font-size: 14px !important;
}

.loaderWrap {
  width: 300px;
  height: 250px;
  text-align: center;

  p {
    font-size: 16px;
    margin-top: 10px;
    color: $grey;
    text-transform: lowercase;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}

.eStoreProduct {
  border-color: #f1f1f1;
  overflow: hidden;
  margin-bottom: 25px;

  .card-body {
    padding: 12px 10px;
    font-size: 14px;
    color: $lightGray;
  }

  span {
    font-weight: 500;
    color: $grey;
  }

  .short-text {
    color: $lightGray;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.4;
    display: block;
    margin-top: 5px;
  }

  .readMoreText {
    color: $mediumGray !important;
  }

  .card-title {
    font-weight: 500;
    font-size: 16px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    overflow: hidden;
    -webkit-box-orient: vertical;
    color: $grey;
    margin-bottom: 8px;
  }
}

.productImg {
  height: 210px;
  width: 100%;
  background-color: $white;
  object-fit: contain;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.productDetails {
  label {
    font-weight: 500;
    text-transform: uppercase;
    margin-top: 15px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 0;
    font-size: 15px;
  }

  .btn-group {
    display: block;
    margin-bottom: 10px;
  }

  & + .table,
  & + .table-responsive .table {
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.productColor {
  width: 18px;
  height: 18px;
  border-radius: 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.06) !important;
  padding: 0;
  box-shadow: none !important;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.productColor.active {
  transform: scale(1.4);
}

.productSize {
  width: 32px;
  height: 32px;
  border-radius: 50% !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
  color: $mediumGray !important;
  padding: 0;
  background-color: $white !important;
  box-shadow: none !important;
  font-weight: 500;
  cursor: pointer;
  line-height: 30px;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.productSize.active {
  border: 1px solid $theme-color !important;
  color: $white !important;
  background-color: $theme-color !important;
}

.productThumbnail {
  min-width: 60px;
  width: 60px;
  margin-right: 10px;
}

.productDescription {
  margin-top: 25px;

  .ql-editor {
    height: 190px;
    max-height: 190px;
    overflow: auto;
  }

  h2 {
    margin-top: 0;
    font-size: 24px;
  }

  p {
    font-size: 15px;
    max-width: 80%;
  }
}

.productCarousel {
  position: relative;
  overflow: hidden;

  .carousel,
  .carousel-inner,
  .carousel-item,
  .carousel .carousel-item img {
    height: 500px;
    width: 100%;
    border-radius: 12px;
  }

  .carousel .carousel-item img {
    object-fit: cover;
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
    width: 42px;
    top: 45%;
    height: 10%;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 24px;
    width: 24px;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.3);
    border-radius: 50%;
    line-height: 25px;
    background-image: none;

    &::before {
      font-size: 14px;
      color: #ccc;
      content: "\f054";
      font-family: "FontAwesome";
      // top: 50%;
      // left: 50%;
      // -ms-transform: translate(-50%, -50%);
      // transform: translate(-50%, -50%);
    }
  }

  .carousel-control-next-icon {
    padding-left: 2px;
  }

  .carousel-control-prev-icon {
    padding-right: 2px;

    &:before {
      content: "\f053";
    }
  }

  .carousel-indicators {
    margin-bottom: 0;
    width: fit-content;
    margin: unset;
    left: 50%;
    transform: translate(-50%, 0);
    background: $grey;
    border-radius: 10px;
    padding: 4px 6px;
    max-width: 100%;
    bottom: 12px;

    li {
      width: 6px;
      min-width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 0;
      background: #eee;
    }

    .active {
      background: linear-gradient(
        rgba(var(--primary-color-rgb), 1),
        rgba(var(--primary-color-rgb), 0.5)
      );
    }
  }
}

.ordersPg {
  img {
    height: 65px;
    width: 65px;
    border: 1px solid #e0e0e0;
    border-radius: 50%;
    padding: 2px;
    object-fit: cover;
  }

  h5 {
    font-size: 16px;
    color: $grey;
    font-weight: 500;
    margin-bottom: 2px;
  }

  p {
    color: $mediumGray;
    font-size: 14px;
    margin-bottom: 2px;

    span {
      margin-left: 3px;
      color: $theme-color;
      display: inline-block;
      font-weight: 500;
      font-style: italic;
    }
  }

  h6 {
    color: $lightGray;
    margin-bottom: 4px;
    font-weight: 500;
    margin-top: 15px;
    font-size: 14px;
  }
}

.ordersPgList {
  .list-group-item {
    p {
      justify-content: space-between;
    }
  }
}

input[type="text"]:disabled {
  cursor: not-allowed;
  background: #dddddd !important;
}

// =========== Pagination ===========
.pagination .page-item .page-link,
.pagination .page-item,
.pagination .previous a,
.pagination .next a {
  border-radius: 50%;
  margin: 0 5px;
  color: #797979 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 6px 12px;
  border: none;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
}

.pagination .page-item.active a {
  background: $theme-color !important;
  font-size: 13px;
  color: white !important;
  border-radius: 6px;
  padding: 6px 14px;
}

.react-bs-table-container {
  .page-link {
    line-height: 1;
  }

  .paginationWrap {
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.uploadLogo {
  margin-bottom: 20px;

  p {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .productLogoPreview {
    width: 100px;
    height: 100px;
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    display: block;
    margin-bottom: 8px;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }

    label {
      cursor: pointer;
      background-color: $theme-color !important;
      color: $white;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      line-height: 30px;
      margin-bottom: 0;
      position: absolute;
      bottom: 0;
      right: -2px;
      text-align: center;
    }
  }

  small {
    color: $lightGray;
  }
}

.customizeProductTable {
  margin-top: 20px;
  margin-bottom: 25px;
}

.productDescription {
  .ql-editor {
    height: 130px;
    max-height: 130px;
    // overflow: auto;
  }
}

// order details
.OrderDetails {
  // background-color: #f8f8f8;
  padding: 20px;
  border-radius: 10px;

  .orderedProdcut {
    // max-width: 300px;
    margin: auto;
    text-align: center;

    img {
      width: 200px;
      aspect-ratio: 1/1;
      margin: auto;
      object-fit: cover;
      border-radius: 10px;
    }

    h2 {
      font-size: 16px;
      margin: 15px 0 8px;
    }

    .productinfo {
      padding: 0;
      margin: 0;
      list-style: none;

      // display: flex;
      // justify-content: center;
      // align-items: center;
      li {
        text-align: left;
        margin-right: 5px;
        line-height: 1;
        display: flex;
        align-items: center;
        // border-right: 1px dashed rgba( $mediumGray, 0.5);
        padding-right: 6px;

        &:last-child {
          border-right: none;
        }

        span,
        b,
        .btn {
          font-size: 14px;
          font-weight: 300;
        }

        span {
          padding-left: 20px;
        }

        b {
          width: 35%;
          font-weight: 600;
          display: inline-flex;
          justify-content: space-between;
        }

        .btn {
          color: $theme-color !important;
        }

        select {
          width: auto;
          height: auto;
          background-color: transparent !important;
          border: none;
          padding: 0;
        }
      }
    }
  }

  .cancelButton {
    text-align: center;
    margin-bottom: 30px;

    button {
      max-width: 300px;
      width: 100%;
      font-weight: 500;
      border-color: $theme-color !important;
      color: $theme-color;
      height: 46px;

      &:hover {
        background-color: $theme-color !important;
        color: $white;
      }
    }
  }

  // cancelled
  .Cancelled {
    margin-top: 20px;
    background-color: $white;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .cancelledIcon {
      width: 80px;
      display: flex;
      align-items: center;
      justify-content: center;

      i {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f5f5f5;
        color: rgba($mediumGray, 0.6);
      }
    }

    .CancelledContent {
      flex: 1 1;

      .CancelledTitle {
        font-size: 16px;
        font-weight: 600;
      }

      .CancelledCard-para {
        color: rgba($mediumGray, 0.8);
        font-size: 14px;
      }
    }
  }

  .orderAddress {
    padding: 20px;
    border-radius: 10px;

    h4 {
      font-size: 20px;
      font-weight: 500;
    }

    .userName {
      margin-bottom: 8px;
      margin-top: 18px;

      span {
        font-size: 18px;
        font-weight: 500;
        padding: 0 10px;
        border-left: 1px solid #eaeaec;
        line-height: 1;

        &:first-child {
          padding-left: 0;
          border-left: none;
        }
      }
    }

    p {
      margin: 0;
      font-size: 14px;
      color: rgba($black, 0.5);
    }
  }

  .orderDetail-price {
    background-color: $white;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;

    p {
      margin: 0;
      font-weight: 600;
      font-size: 18px;

      span {
        display: block;
        font-size: 14px;
        color: #696e79;
        font-weight: 400;
        margin-bottom: 6px;

        strong {
          font-weight: 600;
          color: $theme-color;
        }
      }

      &.price {
        font-weight: 600;
        font-size: 20px;
      }
    }
  }

  // .updateInfo {
  //   background-color: $white;
  //   border-radius: 10px;
  //   h4 {
  //     font-size: 18px;
  //     font-weight: 600;
  //   }
  //   ul {
  //     margin-top: 10px;
  //     padding: 0;
  //     margin-bottom: 0;
  //     li {
  //       list-style: none;
  //       font-size: 14px;
  //       padding: 6px 0;
  //       font-weight: 300;
  //       color: $lightGray;
  //       img {
  //         margin-right: 10px;
  //       }
  //     }
  //   }
  // }
}

.orderTrack {
  background-color: $white;
  padding: 40px 20px;
  border-radius: 6px;
  margin-top: 30px;
  margin-bottom: 30px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;

    li {
      position: relative;
      z-index: 1;
      flex: 1 1;

      span {
        &.dot {
          width: 10px;
          height: 10px;
          background-color: #eaeaec;
          display: inline-block;
          border-radius: 50%;
        }

        &.status {
          font-size: 12px;
          color: rgba($mediumGray, 0.8);
        }

        &.date {
          font-size: 12px;
          color: rgba($mediumGray, 0.8);
          white-space: nowrap;
        }
      }

      &.active {
        span {
          &.dot {
            background-color: green;
          }
        }
      }
    }
  }
}

.feedCarousel,
.profileCarousel {
  position: relative;
  overflow: hidden;

  .carousel,
  .carousel-inner,
  .carousel-item,
  .carousel .carousel-item .feedCarouselImg,
  .carousel .carousel-item video {
    height: 600px;
    width: 100%;
    border-radius: 12px;
  }

  .carousel .carousel-item .feedCarouselImg {
    object-fit: cover;
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
    width: 42px;
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    height: 40px;
    width: 40px;
    background-color: rgba(22, 21, 21, 0.4);
    // box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.3);
    box-shadow: none !important;
    border-radius: 50%;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel-control-next-icon {
    padding-left: 2px;
  }

  .carousel-control-prev-icon {
    padding-right: 2px;
  }

  .carousel-control-next-icon:before,
  .carousel-control-prev-icon:before {
    font-size: 15px;
    color: #fff;
  }

  .audioWrapperFeedCarousel {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.025);
    border-radius: 12px;
  }

  .carousel-indicators {
    margin-bottom: 0;
    width: fit-content;
    margin: unset;
    left: 50%;
    transform: translate(-50%, 0);
    background: $grey;
    border-radius: 10px;
    padding: 4px;
    max-width: 100%;
    bottom: 6px;

    li {
      width: 6px;
      min-width: 6px;
      height: 6px;
      border-radius: 50%;
      border: 0;
      background: #eee;
    }

    .active {
      background: linear-gradient(
        rgba(var(--primary-color-rgb), 1),
        rgba(var(--primary-color-rgb), 0.5)
      );
    }
  }

  .mediaCountCarousel {
    z-index: 99;
    right: 10px;
    left: unset;
    top: 10px;
  }

  .carousel .carousel-item video {
    background-color: #111;
  }

  video::-webkit-media-controls-fullscreen-button {
    display: none;
  }
}

.profileCarousel {
  border: 0;
  width: 100%;

  .carousel,
  .carousel-inner,
  .carousel-item {
    min-height: 280px;
    height: 280px;
    border-radius: 0;
  }

  .carousel .carousel-item {
    img,
    video {
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 0;
    }

    video {
      object-fit: contain;
    }
  }

  .carousel-control-next,
  .carousel-control-prev {
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  .carousel-control-next {
    right: -20% !important;
    transition: right 0.4s ease-in;
  }

  .carousel-control-prev {
    left: -20% !important;
    transition: left 0.1s ease-in;
  }

  &:hover {
    .carousel-control-next,
    .carousel-control-prev {
      opacity: 1;
    }
  }

  &:hover {
    .carousel-control-next {
      right: 0 !important;
    }
  }

  &:hover {
    .carousel-control-prev {
      left: 0 !important;
    }
  }
}

.deliveryAddress {
  h4 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
    text-transform: capitalize;
  }

  li {
    display: flex;
    margin-bottom: 18px;

    &:last-child {
      margin-bottom: 0 !important;
    }

    span {
      font-size: 14px;
      margin-left: 12px;
      text-transform: capitalize;
    }

    img {
      width: 20px !important;
    }
  }
}

// help module
.content {
  padding-top: 30px;

  // tabs
  .tab-content {
    border: none;
    background-color: #f5f6f8;
  }

  // custom control
  .filterWrap {
    .custom-control {
      padding-left: 1.3rem;
      display: flex !important;
      min-height: 0 !important;

      .custom-control-label::before,
      .custom-control-label::after {
        left: -1.3rem;
        border-radius: 4px;
        width: 15px !important;
        height: 15px !important;
      }
    }

    > div:first-child {
      margin-bottom: 0;

      > * {
        margin-bottom: 15px;
      }
    }
  }

  // nav pills
  .nav-pills {
    margin-bottom: 15px;

    &.navTabs {
      justify-content: center;
    }

    .nav-item {
      .nav-link {
        border-radius: 6px !important;
        padding: 10px 18px;
        cursor: pointer;
        position: relative;

        &::after {
          content: " ";
          position: absolute;
          top: auto;
          left: 50%;
          bottom: -5px;
          transform: translateX(-50%) rotate(45deg);
          width: 10px;
          height: 10px;
          background-color: transparent;
          // will-change: background-color;
          // transition: 0.3s ease-in;
        }

        &.active {
          color: $white !important;
          background-color: $theme-color;

          &::after {
            background-color: $theme-color;
          }
        }
      }
    }

    .nav-item:not(:first-child) .nav-link {
      margin-left: 10px;
    }
  }

  .nav-pills:not(.flex-column) .nav-item:first-child .nav-link,
  .nav-pills:not(.flex-column) .nav-item:last-child .nav-link {
    border-radius: 6px !important;
  }

  // table
  .react-bs-table-container {
    table {
      margin-top: 0;
    }
  }

  // pagination
  .react-bs-table-pagination {
    .pagination {
      margin-bottom: 0;
    }
  }

  .form-check {
    padding-left: 0;
  }
}

// category modal upload
.category-modal-upload {
  padding: 7px;
  width: 100%;
  height: 100%;
  max-height: 200px;
  border-radius: 8px;
  // border: 1px dashed #e2e2e2;
  background-color: #eee;

  label {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .add {
      width: 40px;

      img {
        width: 100%;
      }
    }

    .remove {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      i {
        position: absolute;
        bottom: 10px;
        right: 10px;
        padding: 8px;
        font-size: 10px;
        background: white;
        border-radius: 100%;
        box-shadow: 0px 4px 10px -3px #333;
      }
    }
  }
}

// category modal upload
.add-category-modal {
  .category-modal-upload {
    height: 200px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      &.uploadIcon {
        width: 140px;
      }
    }
  }
}

.newTopicsContainer {
  margin: 10px 0 15px;

  .card {
    padding: 5px;
    border: 1px solid #e4e4e4;
    box-shadow: none;

    .card-header {
      display: flex;
      gap: 15px;
      padding: 10px 6px;
      border-bottom: 0 !important;
      background-color: transparent !important;
    }

    .card-body {
      padding: 10px;
      border-top: 1px solid $border;
    }

    ul {
      padding: 0;
      width: 100%;
      height: 100%;
      list-style: none;
      margin-bottom: 0;
      max-height: 240px;

      li {
        margin-bottom: 10px;
      }
    }
  }

  // render content in new topics modal
  .renderTopics {
    // max-height: 200px;
    // overflow-y: auto;

    .category-modal-upload {
      border: 1px dashed $border;
      background-color: transparent;
      max-height: 100%;

      .upload.inline {
        width: 100%;

        .upload-content {
          gap: 5px;
          flex: 1;
          display: flex;
          flex-direction: column;
          padding-left: 10px;

          .uploadDate {
            font-size: 12px;
            color: #9b9b9b;
          }

          .uploadTitle {
            flex: 1;
            font-size: 14px;
            line-height: 1.3;
            margin: 0px 0 5px;
            font-weight: 600;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .uploadedContentBottom {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            color: #9b9b9b;
          }
        }

        img {
          width: 60px;
          height: 80px;
          padding-right: 0;
          object-fit: cover;
          border-radius: 5px;
        }
      }
    }
  }

  // custom control
  .custom-control {
    padding-left: 25px;

    .custom-control-label {
      &::after,
      &::before {
        left: -25px;
        width: 15px;
        height: 15px;
        border-radius: 4px;
      }
    }
  }
}

// upload content in new topics modal
.uploadTopics,
.renderTopics {
  .upload.inline {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    img {
      width: 30px;
      padding-right: 10px;
    }
  }
}

.renderTopics {
  h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 15px;
  }
}

.uploadItems {
  display: flex;
  overflow: auto;
  scroll-snap-type: x mandatory;
  gap: 15px;

  &:nth-child(2) {
    &:not(:empty) {
      margin-bottom: 20px;
    }
  }
}

.topicUploadedItem {
  flex: 0 0 40%;
  scroll-snap-align: start;
  border-radius: 4px;
  overflow: hidden;
  border: 1px dashed $border;
  padding: 10px;

  .topicUploadedImage {
    width: 100%;
    height: 150px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;

    video {
      height: 150px;
      width: 100%;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }

    // .custom-checkbox.custom-control {

    //   label {
    //     color: $white;
    //     font-weight: 500;
    //   }
    // }
  }

  .topicUploadedContent {
    position: relative;
    margin-top: 10px;

    .input-group {
      input {
        height: 36px;
      }

      button {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        height: 36px;
        margin: 0;
      }
    }

    h1 {
      font-size: 13px;
      line-height: 1.4;
      margin: 0;
      font-weight: 600;
      margin-bottom: 4px;
      text-transform: capitalize;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
    }

    span {
      font-size: 12px;
      color: $mediumGray;
      font-weight: 400;
    }

    .uploadedAction {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      margin-bottom: 0px;

      button {
        margin: 0 6px 0 0;
        padding: 4px 10px;
        border-radius: 6px;
        height: auto;
        font-size: 12px;

        &.btn-outline-secondary {
          background-color: transparent !important;
          color: $theme-color !important;
          border: 1px solid $theme-color;

          &:hover {
            background-color: $theme-color !important;
            color: $white !important;
          }
        }

        i {
          margin: 0 !important;
        }

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}

// select
.select__control {
  border-radius: 8px !important;
  min-height: 46px !important;
  box-shadow: none !important;
  border: transparent !important;
  background-color: #eff0f6 !important;

  .select__input {
    font-size: 14px;
    min-width: 150px !important;
  }

  .select__placeholder {
    font-size: 14px;
  }
}

// upload content in new topics modal
.uploadTopics,
.renderTopics {
  .upload.inline {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    img {
      width: 30px;
      padding-right: 10px;
    }
  }
}

.additionalInfo {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px;

  .additionalHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    border-bottom: 1px dashed $border;
    padding-bottom: 20px;

    button {
      height: auto !important;
      padding: 4px 15px;
    }

    label {
      text-transform: capitalize;
      cursor: pointer;
    }
  }

  input {
    margin-top: 0;
    margin-right: 10px;
  }

  .additionalContent {
    display: flex;
    align-items: center;

    button {
      margin: 0;
      padding: 0;
      padding-left: 20px;
    }
  }
}

.basic-multi-select {
  z-index: 3;
}

.sun-editor {
  .se-wrapper {
    .se-wrapper-inner {
      height: auto !important;
    }
  }
}

.search-box {
  width: auto;
}

.leaderBoardTable {
  border: 1px solid rgba(201, 201, 201, 0.8);
  border-radius: 20px;
  padding: 0 10px 10px;
  background-color: $white;

  table {
    margin-bottom: 0;
  }

  p {
    font-size: 14px;
    margin-bottom: 0;
    color: $mediumGray;
    font-weight: 500;
    line-height: 1.25;

    & + span {
      font-size: 12px;
    }
  }

  svg {
    height: 30px;
    width: 30px;
    margin-right: 20px;
  }
}

.fanImg {
  width: 38px;
  border-radius: 50%;
  height: 38px;
  min-width: 38px;
  border: 1px solid rgba($black, 0.08);
}

.progressTrackingWrapper {
  .progressTrackingWrap {
    h6 {
      font-size: 14px;
      margin-bottom: 12px;
      color: $mediumGray;
      font-weight: 600;

      &:not(:first-of-type) {
        margin-top: 10px;
      }
    }

    p {
      font-size: 12px;
      color: $mediumGray;
      margin-bottom: 6px;
      font-weight: 400;
      line-height: 1.3;
    }

    i {
      margin-bottom: 8px;
      font-size: 12px;
      margin-left: 5px;
    }

    .progressTracking {
      margin-inline: 5px;
      width: calc(33.3333% - 10px);
      align-self: flex-end;
      margin-bottom: 20px;

      .progress {
        height: 6px;
        border-radius: 30px;
      }

      .progress-bar {
        background: $theme-color;
      }
    }

    .d-flex {
      .progressTracking {
        &:first-of-type,
        &:nth-child(4) {
          margin-left: 0;
        }
      }
    }
  }
}

.dropdown-item {
  padding: 7px 15px 6px;
}

// media styles
@media all and (min-width: 992px) and (max-width: 1199.98px) {
  .app-header .navbar-brand {
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -77.5px;
  }
}

@media (max-width: 991.98px) {
  .content {
    .filterWrap > div:first-child > * {
      flex-grow: 0;
    }

    .filterWrap > div {
      width: auto;
    }
  }

  .modal-dialog {
    max-width: 90%;
  }

  .app-header .navbar-brand {
    transform: translate(-50%, 0);
  }
}

@media all and (min-width: 768px) {
  // ordertrack
  .orderTrack {
    ul {
      align-items: center;
      justify-content: space-between;

      li {
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background-color: rgba($mediumGray, 0.2);
          z-index: -1;
        }

        &::before {
          content: "";
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
          right: 0;
          width: 0;
          height: 1px;
          background-color: green;
          z-index: -1;
        }

        span {
          &.status {
            position: absolute;
            left: 0;
            top: -30px;
          }

          &.date {
            position: absolute;
            left: 0;
            bottom: -30px;
          }
        }

        &.end {
          span {
            &.status,
            &.date {
              right: 0;
              left: auto;
            }
          }
        }

        &:nth-child(1) {
          &.active {
            &::before {
              animation: process 0.8s ease-in-out 1s 1 forwards;
            }

            span {
              &.dot {
                animation: dotColor 0.8s ease-in-out 1s 1 forwards;
              }

              &.status {
                animation: textcolor 0.8s ease-in-out 1s 1 forwards;
              }
            }
          }
        }

        &:nth-child(2) {
          &.active {
            &::before {
              animation: process 0.8s ease-in-out 2s 1 forwards;
            }

            span {
              &.dot {
                animation: dotColor 0.8s ease-in-out 2s 1 forwards;
              }

              &.status {
                animation: textcolor 0.8s ease-in-out 2s 1 forwards;
              }
            }
          }
        }

        &:nth-child(3) {
          &.active {
            &::before {
              animation: process 0.8s ease-in-out 3s 1 forwards;
            }

            span {
              &.dot {
                animation: dotColor 0.8s ease-in-out 3s 1 forwards;
              }

              &.status {
                animation: textcolor 0.8s ease-in-out 3s 1 forwards;
              }
            }
          }
        }

        &:nth-child(4) {
          &.active {
            &::before {
              animation: process 0.8s ease-in-out 4s 1 forwards;
            }

            span {
              &.dot {
                animation: dotColor 0.8s ease-in-out 4s 1 forwards;
              }

              &.status {
                animation: textcolor 0.8s ease-in-out 4s 1 forwards;
              }
            }
          }
        }

        &:last-child {
          flex: 0;

          &::after {
            display: none;
          }

          &::before {
            display: none;
          }

          &.cancelled {
            span {
              &.dot {
                animation: CanceldotColor 0.8s ease-in-out 4s 1 forwards;
              }

              &.status {
                animation: Canceltextcolor 0.8s ease-in-out 4s 1 forwards;
              }
            }
          }
        }
      }
    }
  }

  @keyframes process {
    from {
      width: 0;
    }

    to {
      width: 100%;
    }
  }
}

@media all and (max-width: 767.98px) {
  .myOrderWrapper {
    .myOrderItem {
      .orderActionButton {
        button {
          height: auto;
          width: 150px;
        }
      }
    }
  }

  .orderTrack {
    ul {
      height: 300px;
      flex-direction: column-reverse;

      li {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        span {
          display: block;

          &.dot {
            margin-bottom: -5px;
            margin-left: -5px;
          }

          &:not(.dot) {
            margin-left: 10px;
            margin-bottom: -10px;
          }
        }

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          width: 1px;
          height: 100%;
          background-color: rgba($mediumGray, 0.2);
          z-index: -1;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 1px;
          height: 0;
          background-color: green;
          z-index: -1;
        }

        &:nth-child(1) {
          &.active {
            &::before {
              animation: Mobileprocess 0.8s ease-in-out 1s 1 forwards;
            }

            span {
              &.dot {
                animation: dotColor 0.8s ease-in-out 1s 1 forwards;
              }

              &.status {
                animation: textcolor 0.8s ease-in-out 1s 1 forwards;
              }
            }
          }
        }

        &:nth-child(2) {
          &.active {
            &::before {
              animation: Mobileprocess 0.8s ease-in-out 2s 1 forwards;
            }

            span {
              &.dot {
                animation: dotColor 0.8s ease-in-out 2s 1 forwards;
              }

              &.status {
                animation: textcolor 0.8s ease-in-out 2s 1 forwards;
              }
            }
          }
        }

        &:nth-child(3) {
          &.active {
            &::before {
              animation: Mobileprocess 0.8s ease-in-out 3s 1 forwards;
            }

            span {
              &.dot {
                animation: dotColor 0.8s ease-in-out 3s 1 forwards;
              }

              &.status {
                animation: textcolor 0.8s ease-in-out 3s 1 forwards;
              }
            }
          }
        }

        &:nth-child(4) {
          &.active {
            &::before {
              animation: Mobileprocess 0.8s ease-in-out 4s 1 forwards;
            }

            span {
              &.dot {
                animation: dotColor 0.8s ease-in-out 4s 1 forwards;
              }

              &.status {
                animation: textcolor 0.8s ease-in-out 4s 1 forwards;
              }
            }
          }
        }

        &:last-child {
          flex: 0;

          &::after {
            display: none;
          }

          &::before {
            display: none;
          }

          &.cancelled {
            span {
              &.dot {
                animation: CanceldotColor 0.8s ease-in-out 4s 1 forwards;
              }

              &.status {
                animation: Canceltextcolor 0.8s ease-in-out 4s 1 forwards;
              }
            }
          }
        }
      }
    }

    @keyframes Mobileprocess {
      from {
        height: 0;
      }

      to {
        height: 100%;
      }
    }
  }

  .OrderDetails {
    .Cancelled {
      justify-content: center;

      .CancelledContent {
        flex: inherit;
      }
    }
  }

  .loginPgBg .card-group {
    padding: 8px 5px 10px;
  }

  .loginPgBg h2 {
    font-size: 21px;
  }
}

// @media (max-width: 1450px) {
//   .details-card-xl > div {
//     display: flex;
//     align-items: flex-start;
//     flex: 0 0 100%;
//     max-width: 100%;
//   }

//   .details-card-xl > div > .ProfileCard:first-child {
//     margin-right: 15px;
//   }

//   .details-card-xl > div > .ProfileCard:last-child {
//     margin-left: 15px;
//   }
// }

.details-card-xl {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(49%, 1fr));
  grid-auto-rows: 10px;
  grid-auto-flow: dense;
  gap: 15px;
}

@media (max-width: 1450px) and (min-width: 1200px) {
  .accordion ul li {
    .card-title,
    .custom-card-title {
      max-width: 20ch !important;
    }
  }
}

@media (max-width: 1300px) {
  .react-bs-container-body,
  .react-bs-container-header {
    .table {
      // width: 200%;
      width: 165%;
    }
  }

  .productPg {
    .react-bs-container-header .table,
    .react-bs-container-body .table {
      width: 100%;
    }
  }

  .productCarousel {
    margin-bottom: 35px;
  }

  .productDetails {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (min-width: 1200px) {
  .react-bs-container-body,
  .react-bs-container-header {
    .table {
      width: 150%;
    }
  }

  .customWidthTable {
    .react-bs-container-body,
    .react-bs-container-header {
      .table {
        width: 100%;
      }
    }
  }

  .productPg {
    .react-bs-container-header .table,
    .react-bs-container-body .table {
      width: 100%;
    }
  }

  .app-header.navbar {
    flex-wrap: nowrap !important;
  }

  .container {
    max-width: 100% !important;
  }

  .details-page {
    .container {
      max-width: 80% !important;
    }
  }
}

@media (min-width: 992px) and (max-width: 1450px) {
  .app-header {
    .nav-item {
      padding: 0 6px;

      .nav-link {
        font-size: 12px !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .box-container {
    width: 100% !important;

    h2 {
      font-size: 24px !important;
    }
  }
}

@media (max-width: 991.98px) {
  .filterWrap {
    flex-wrap: wrap;

    > div:first-child {
      flex-wrap: wrap;
    }

    > div {
      width: 100%;
    }

    > div:first-child > * {
      flex-grow: 1;
    }
  }

  .filterWrap.no-wrap > div {
    width: auto !important;
  }

  .w-md-100 {
    width: 100% !important;
  }

  .DateRangePicker {
    width: 100% !important;
  }

  .profileInfo .list-group-item.email p {
    flex-wrap: wrap;

    span {
      margin-left: 0 !important;
    }
  }

  .events-view h3 {
    font-size: 24px;
    margin-bottom: 15px !important;
  }

  .react-bs-container-body .table,
  .react-bs-container-header .table {
    width: 225%;
  }

  .progressTrackingWrapper {
    .progressTrackingWrap {
      .progressTracking {
        width: calc(25% - 10px);
      }

      .d-flex {
        .progressTracking {
          &:first-of-type,
          &:nth-child(5) {
            margin-left: 0;
          }

          &:nth-child(4) {
            margin-left: 5px;
          }
        }
      }
    }
  }
}

// Course Page
.course-table {
  table {
    width: 100% !important;
  }
}
